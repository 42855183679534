import React from 'react'
import '../styles/global.css'

export default function NotFound() {
  return (
    <div className="content">
      <h2>404</h2>
      <p>Sorry, this page does not exist 🙁</p>
    </div>
  )
}
